<template>
<div>
  <!-- {{this.$store.state.flag2}} -->
    <div class="like css-helper" v-if="flag">
      <div class="order-title">我的预约</div>
      <div
        class="order-outer"
        v-for="(item, index) in appointmentList"
        :key="index"
      >
        <div class="order-time">
          <div>预约时间:{{ item.create_time }}</div>
          <div class="success" v-show="item.yy_status == 1">预约成功</div>
          <div class="success erro" v-show="item.yy_status == 2">取消预约</div>
        </div>
        <div class="order-details flex">
          <div
            class="image-outer cursor-pointer flex-item"
            @click="gotos(item.id)"
          >
            <img class="image" src="../../assets/ucenter/orderp.png" alt="" />
          </div>
          <div class="text flex-item flex-1">
            <div class="order-title">
              <span
                class="cursor-pointer"
                @click="gotos(item.id)"
              >
                博物馆观览票务预约</span
              >
            </div>
            <div class="order-id">预约编号：{{ item.yy_code }}</div>
            <div class="name">
              <span class="name1">姓</span>名：{{ item.name }}
            </div>
            <div class="time">
              参观时间：<span class="time-inner">{{ item.day_time_stamp }}</span>
            </div>

            <div
              class="detail-btn"
              @click="gotos(item.id)"
            >
              详情
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        v-if="total !== 0"
        class="pagenation"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
  </div>
    

  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      appointmentList: [],
      total: 0,
      pageindex: 1,
      pagesize: 8,
      detailsobj: {},
      flag:true,
      id: '',
    };
  },
  mounted() {
    this.goData();
  },
  methods: {
    gotos(id){
      this.id = id
        this.$router.push('/Booking_details?id=' + this.id)
      this.goDats()
    },
    goData() {
      let query = {
        source: "pc",
        page: this.pageindex,
        pagesize: this.pagesize,
      };
      this.$post("/myOrder", query).then((res) => {
        const data = res.data.data;
        this.total = res.data.total_count;
        data.map((item) => {
          item.day_time_stamp = moment(
            1000 * parseInt(item.day_time_stamp)
          ).format("YYYY-MM-DD");
          item.create_time = moment(1000 * parseInt(item.create_time)).format(
            "YYYY-MM-DD"
          );
        });
        this.appointmentList = data;
      });
    },
        goDats() {
      let query = {
        source: "pc",
        id: this.id,
      };
      this.$post("/orderDetail", query).then((res) => {
        this.detailsobj = res.data;
        this.detailsobj.day_time_stamp = moment(
          1000 * parseInt(this.detailsobj.day_time_stamp)
        ).format("YYYY-MM-DD");
        this.detailsobj.create_time = moment(
          1000 * parseInt(this.detailsobj.create_time)
        ).format("YYYY-MM-DD");
      });
    },
    btn() {
      let query = {
        source: "pc",
        id: this.id,
      };
      // let obj = {
      //   name: "我的预约",
      //   value: "order",
      // };
      this.$post("/orderCancle", query).then((res) => {
        this.popupMsg = res.msg;
        this.showPopupMsg();
        this.toTop();
        this.$store.state.flag = true
        // this.$emit("change", obj);
        setTimeout(() => this.goData(), 2000);
      });
    },
    showPopupMsg() {
      this.showPopupMessage(this.popupMsg, this.popupCode === 1);
    },
    toTop() {
      let el = document.querySelector("#frame_content_view");
      if (el) {
        el.scrollTop = 0;
      }
    },
    handleCurrentChange(val) {
      this.pageindex = val;
      this.goData();
    },
  },
  destroyed(){
              this.page = true
            }
};
</script>
<style lang="scss" scoped>
.like {
  color: rgba(51, 51, 51, 1);
  .order-title {
    font-size: 24px;
    font-weight: 600;
    font-family: SourceHanSansCN;
    color: #333;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6f0;
    margin-bottom: 26px;
  }
  .order-outer {
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-bottom: 30px;
    .order-time {
      background-color: rgba(247, 247, 247, 1);
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-radius: 4px 4px 0px 0px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      & .success {
        color: rgba(51, 166, 119, 1);
        font-size: 14px;
      }
      & .erro {
        color: red;
      }
    }
    .order-details {
      padding: 20px;
      .image-outer {
        height: 185px;
        margin-right: 40px;
        .image {
          width: 277px;
          height: 185px;
        }
      }
      .text {
        // flex: 3;
        position: relative;
        .order-title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 18px;
        }
        .order-id,
        .name,
        .time {
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          margin-bottom: 5px;
        }
        .time {
          & .time-inner {
            color: rgba(189, 149, 93, 1);
          }
        }
        .name1 {
          letter-spacing: 28px;
        }
        .detail-btn {
          position: absolute;
          text-align: center;
          color: rgba(167, 141, 99, 1);
          font-size: 14px;
          width: 60px;
          right: 0px;
          bottom: 0px;
          cursor: pointer;
        }
      }
    }
  }
  .pagenation {
    text-align: center;
  }
}

.order-detail {
  padding: 30px 0 40px;
  .top {
    background: url("../../assets/ucenter/order-detail-bg.png") top center
      no-repeat;
    background-size: 818px 506px;
    padding: 40px 80px 80px;
    color: #333333;
    position: relative;
    .order-title {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .item {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin: 0 48px;
      font-size: 16px;
      height: 54x;
      line-height: 54px;
      border-bottom: 1px solid rgba(223, 222, 217, 1);

      .left {
        color: rgba(136, 136, 136, 1);
      }
    }
    .item:nth-child(7) {
      border-bottom: none !important;
    }
    .cancel {
      color: #fff;
      background-color: #bd955d;
      border-radius: 10px;
      font-weight: bolder;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 216px;
      line-height: 48px;
      top: 430px;
      font-size: 18px;
      &.disable {
        background-color: #d6d4d4;
        color: #999999;
      }
    }
  }
  .center {
    margin: 35px 0 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6f0;
    .image1 {
      width: 30px;
      height: 30px;
      background-image: url("../../assets/ucenter/notice.png");
      margin-right: 5px;
    }
    .notice-title {
      font-size: 20px;
      color: #b26c30;
    }
  }
  .bottom {
    font-size: 14px;
    color: #333333;
    .item {
      margin-bottom: 20px;
      line-height: 26px;
      .text-red {
        color: #da0112;
      }
      .image2 {
        width: 16px;
        height: 16px;
        background-image: url("../../assets/ucenter/notice2.png");
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
}
</style>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #c19c66;
}
</style>
